import { Link, navigate } from "gatsby"
import React, {useEffect } from "react"
import { Helmet } from "react-helmet"
import Layout from "../../components/layout"
import Seo from "./../../components/seo"
import { Button } from "react-bootstrap"
import { Tabbordion, TabPanel, TabLabel, TabContent } from "react-tabbordion"
//import useLiveControl from "./../../controlled/useLiveControl"
import Video from "../../components/video"
import AOS from 'aos'
import 'aos/dist/aos.css'



// accordian
const blockElements = {
  animator: "accordion-animator",
  content: "accordion-content",
  panel: "accordion-panel",
  label: "accordion-title",
}

const customLink = (url, e) => {
  // browserHistory.push('/'+url);
  navigate(url)
}


const RegionsPage = () => {

 // const { isStaging } = useLiveControl()

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <Layout>
      <Seo
        title={"About Regions Home Improvement Financing"}
        description={"As of the date of the merger, Regions is a party to EnerBank customer forms, disclosures, documents, and agreements that name EnerBank as a party, and those forms, disclosures, documents, and agreements should be read with Regions substituted for EnerBank."}
      />
      <Helmet>
        <body id="" className="page-regions" />
      </Helmet>
      <div id="rebrand">
      <section
        className="banner-area"
        id="regions-page-banner-section"
        style={{ backgroundImage: "none" }}
      >
    <div className="container banner-container"
      style={{
                backgroundImage:
                  "url(" +
                  "https://gbdevpress.enerbank.com/wp-content/uploads/2024/04/home-hero-horizontal.jpg" +
                  ")",
              }}
    >
    <div className="relative banner-area-text-container">
          <div className="container text-center">
            <div className="identifier-container row-fluid">
              <div className="element span12">
                  <div className="rhif-identifier span5">
                      <h4>HOME IMPROVEMENT FINANCING</h4>
                  </div>
                  <div className="right-angle span2"></div>
              </div>
            </div>
            <h1 className="text-white text-left desktop" style={{ padding: "80 0" }}>
            	<span>Regions | EnerBank is now</span> <br/>Regions Home Improvement Financing
            </h1>
          </div>
        </div>
  </div>
  <div className="container text-center mobile">
    <h1 className="text-white text-left" style={{ padding: "80 0" }}>
      <span>Regions | EnerBank is now</span> <br/>Regions Home Improvement Financing
    </h1>
  </div>
  </section>
      <section className="paragraph-text-block">
        <div className="container">
          <div className="row justify-content-center">
              <div className="col-md-12">
                <div className="embed-container">
                    <Video
                      videoSrcURL={
                        "https://player.vimeo.com/video/973233341"
                      }
                      allow="autoplay"
                      videoTitle="Consumer Banking Summit"
                      videoWidth="100%"
                      videoHeight="500"
                    />
                  </div>
                </div>
                <div className="col-md-12 mt-5">
                <p>EnerBank USA is now Regions Home Improvement Financing. While we may have a new name, you can expect the same great service you've come to know.</p>
                <p className="mb-0">When you work with us, you can rest easy knowing your lender is backed by the stability and security of an FDIC-insured bank with over 50 years of experience. Combine that with our knowledge of the home improvement industry, you can feel confident in using us for your financing needs.</p>
              </div>

          </div>
        </div>
      </section>
        <section className="stronger-future rds-marquee marquee-green">
          <div className="bg-teal">
            <h2 className="text-center text-white mb-4">A powerful pairing</h2>
          </div>
          <div className="container">
            <div className="text-center text-white">
              <p className="text-white pb-3">In 2021, Regions Financial Corporation acquired EnerBank USA. Regions Bank has long history of being one the nation&#39;s leading providers of commercial, consumer, personal banking, and homebuilder finance services. Now, as Regions Home Improvement Financing, our customers can enjoy the same great service they&#39;ve learned to expect from us as well as a suite of new tools and services.</p>
              <p className="text-center">
                <Button variant="" className="btn btn-gray" onClick={() =>
                          customLink("/homeowner/")
                        }>I'm a Homeowner</Button>
              </p>
            </div>
          </div>
        </section>
      <div>
      <section className="benefits bg-light-gray">
        <div className="container">
            <div className="row">
              <div className="col-12 pb-5">    
                <h2 className="text-center mb-4">You still know us — we still know you </h2>
                <p>For over two decades, EnerBank USA, now Regions Home Improvement Financing, has been helping contractors offer financing to their home improvement customers — getting them the funds they need for HVAC, roofing, siding, painting, window+door, kitchen and bath remodel, outdoor living, and more. </p>
              </div>
            </div>
          </div>
          <div className="container spotlight-offset pt-5">
            <div className="row align-items-center">
              <div className="col-lg-5">
                  <img src="https://gbdevpress.enerbank.com/wp-content/uploads/2024/05/broad-range-of-financial-products.jpg" className="image" alt="stability and security"
              />
              </div>
              <div className="col-lg-7 spotlight-band-copy">
                  <h2>Stability & Security</h2>
                  <p>With over 20 years in the home improvement business, and now with the strength of Regions, we're stronger than ever.</p>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-lg-5">
                  <img src="https://gbdevpress.enerbank.com/wp-content/uploads/2024/06/strong-suite-products.jpg" className="image" alt="strong suite of products"
              />
              </div>
              <div className="col-lg-7 spotlight-band-copy">
                  <h2>Strong Suite of Products</h2>
                  <p>Not only do we still offer a robust selection of home improvement loans, but if you're located within the Regions business footprint, you'll have access to additional banking services to make your financial life even easier and more convenient.</p>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-lg-5">
                  <img src="https://gbdevpress.enerbank.com/wp-content/uploads/2024/06/exceptional-customer-service.jpg" className="image" alt="exceptional customer service"
              />
              </div>
              <div className="col-lg-7 spotlight-band-copy">
                  <h2>Exceptional Customer Service </h2>
                  <p>Our skilled and knowledgeable professionals still have your back, helping you offer financing to your home improvement customers — and getting you the funds you need to get the job done.</p>
              </div>
            </div>
            <div className="row align-items-center">
                <div className="col-lg-5">
                    <img src="https://gbdevpress.enerbank.com/wp-content/uploads/2024/06/superior-technology.jpg" className="image" alt="superior technology" />
                </div>
                <div className="col-lg-7 spotlight-band-copy">
                    <h2>Superior Technology</h2>
                    <p>We&#39;ve got the latest convenient technology to give your customers an easy application experience and quick credit decisions. </p>
                </div>
            </div>
          </div>
        </section>
        <section className="qa-accordion">
            <div className="container">
                <div className="col-md-12 mb-5">
                    <h2 className="text-center mb-4">Q & A for contractors</h2>
                    <p>As we complete our transition to Regions Home Improvement Financing, here are a few questions and answers to help you better understand our brand update</p>
                </div>
            <div className="row">
                <div className="col-md-12">
                    <Tabbordion
                        blockElements={blockElements}
                        animateContent={"height"}
                        mode={"toggle"}
                        className="regions-accordion"
                        name="accordion" >
                        <TabPanel>
                            <TabLabel className="btn btn-link">
                                <div className="row-fluid">
                                    <div className="col-11">
                                        <span className="title-link">What&#39;s in it for me?</span>
                                    </div>
                                    <div className="col-1">
                                        <span className="accordion-icon"></span>
                                    </div>
                                </div>
                            </TabLabel>
                            <TabContent>
                                <p>Regions Home Improvement Financing will continue to deepen relationships with our existing contractors as well as developing tools and resources to keep the process of offering financing convenient and easy.</p>
                            </TabContent>
                        </TabPanel>
                        <TabPanel>
                            <TabLabel className="btn btn-link">
                                <div className="row-fluid">
                                    <div className="col-11">
                                        <span className="title-link">Will I need to do anything differently?</span>
                                    </div>
                                    <div className="col-1">
                                        <span className="accordion-icon"></span>
                                    </div>          
                                </div>
                            </TabLabel>
                            <TabContent>
                                <p>No. As we retire the EnerBank brand, a few things might look a little different, but the application, funding, and disbursement processes will remain as convenient as ever for both you and your customers.
                                </p>
                            </TabContent>
                        </TabPanel>
                        <TabPanel>
                            <TabLabel className="btn btn-link">
                                <div className="row-fluid">
                                    <div className="col-11">
                                        <span className="title-link">What about my customers — will our relationship change?</span>
                                    </div>
                                    <div className="col-1">
                                        <span className="accordion-icon"></span>
                                    </div>
                                </div>
                            </TabLabel>
                            <TabContent>
                                <p>Our name change won&#39;t alter your customer&#39;s loan terms or repayment schedule. We&#39;ll still provide homeowners with the excellent service and support they&#39;re used to.</p>
                            </TabContent>
                        </TabPanel>
                        <TabPanel>
                            <TabLabel className="btn btn-link">
                                <div className="row-fluid">
                                    <div className="col-11">
                                        <span className="title-link">If I need additional information about Regions, who can I contact?</span>
                                    </div>
                                    <div className="col-1">
                                        <span className="accordion-icon"></span>
                                    </div>
                                </div>
                            </TabLabel>
                            <TabContent>
                                <p>Please visit <Link className="eb-link" to="https://www.regions.com">regions.com</Link> or <Link className="eb-link" to="https://www.regions.com/locator">stop by a convenient branch in our 15-state footprint</Link>.</p>
                            </TabContent>
                        </TabPanel>
                        <TabPanel>
                            <TabLabel className=" btn btn-link">
                                <div className="row-fluid">
                                    <div className="col-11">
                                        <span className="title-link">I've got more questions. Who can I talk to?</span>
                                    </div>
                                    <div className="col-1">
                                        <span className="accordion-icon"></span>
                                    </div>
                                </div>
                            </TabLabel>
                            <TabContent>
                                <p>Please contact your relationship manager or call{" "}
                                    <a href="tel:888.390.1220">888.390.1220</a>, option 2</p>
                            </TabContent>
                        </TabPanel>
                    </Tabbordion>
                </div>
             </div>
            </div>
          </section>
        </div>
        <section className="block-timeline bg-light-gray">
          <div className="timeline">
            <div className="container">
              <div className="col-md-12 mb-5">
                <h2 className="text-center mb-4">Our history</h2>
              </div>
              <div className="row">
                <div className="col-md-6"></div>
                <div className="col-md-6">
                  <div className="time-block 1971" data-aos="zoom" data-aos-delay="150">
                    <div className=""
                    data-aos="fade-left"
                    data-aos-offset="200"
                    data-aos-delay="50"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out"
                    >
                        <h3><span className="year">1971</span> Regions Bank opens first branches in Alabama</h3>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="time-block left 1986" data-aos="zoom" data-aos-delay="250">
                      <div className=""
                      data-aos="fade-right"
                      data-aos-offset="200"
                      data-aos-delay="100"
                      data-aos-duration="500"
                      data-aos-easing="ease-in-out"
                      >
                          <h3 className="text-right"><span className="year">1986</span> Regions expands to<br/> four additional states</h3>
                      </div>
                  </div>
                </div>
                <div className="col-md-6"></div>
                <div className="col-md-6"></div>
                <div className="col-md-6">
                  <div className="time-block 1998" data-aos="zoom" data-aos-delay="350">
                      <div className=""
                      data-aos="fade-left"
                      data-aos-offset="200"
                      data-aos-delay="150"
                      data-aos-duration="500"
                      data-aos-easing="ease-in-out"
                      >
                          <h3><span className="year">1998</span>Regions grows to $32.8 billion in assets and 667 locations</h3>
                      </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="time-block left 2002" data-aos="zoom" data-aos-delay="450">
                      <div className=""
                      data-aos="fade-right"
                      data-aos-offset="200"
                      data-aos-delay="250"
                      data-aos-duration="500"
                      data-aos-easing="ease-in-out"
                      >
                          <h3 className="text-right"><span className="year">2002</span>Regions is listed on the<br/> New York Stock Exchange (NYSE:RF)</h3>
                      </div>
                      
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="time-block">
                    <div className=""
                      data-aos="fade-left"
                      data-aos-offset="200"
                      data-aos-delay="300"
                      data-aos-duration="500"
                      data-aos-easing="ease-in-out"
                      >
                          <h3><span className="year">2002</span>Under national bank charter, EnerBank USA starts to provide contractor-offered home improvement financing in all 50 states.</h3>
                      </div>
                  </div>
                </div>
                <div className="col-md-6"></div>
                <div className="col-md-6"></div>
                <div className="col-md-6">
                  <div className="time-block left 2004" data-aos="zoom" data-aos-delay="550">
                    <div className=""
                      data-aos="fade-right"
                      data-aos-offset="200"
                      data-aos-delay="350"
                      data-aos-duration="500"
                      data-aos-easing="ease-in-out"
                      >
                          <h3 className="text-right"><span className="year">2004</span>EnerBank onboards more<br/> than 1,000 contractors</h3>
                      </div>
                  </div>
                </div>
                <div className="col-md-6"></div>
                <div className="col-md-6"></div>
                <div className="col-md-6">
                  <div className="time-block 1998" data-aos="zoom" data-aos-delay="650">
                      <div className=""
                      data-aos="fade-left"
                      data-aos-offset="200"
                      data-aos-delay="400"
                      data-aos-duration="500"
                      data-aos-easing="ease-in-out"
                      >
                          <h3><span className="year">2010</span>EnerBank reaches $1 billion in cumulative loan originations</h3>
                      </div>
                  </div>
                </div>
                <div className="col-md-6"></div>
                <div className="col-md-6"></div>
                <div className="col-md-6">
                  <div className="time-block left 1998" data-aos="zoom" data-aos-delay="750">
                      <div className=""
                      data-aos="fade-right"
                      data-aos-offset="200"
                      data-aos-delay="450"
                      data-aos-duration="500"
                      data-aos-easing="ease-in-out"
                      >
                          <h3 className="text-right"><span className="year">2012</span> EnerBank serves over 5,000 contractors</h3>
                      </div>
                  </div>
                </div>
                <div className="col-md-6"></div>
                <div className="col-md-6"></div>
                <div className="col-md-6">
                  <div className="time-block 1998" data-aos="zoom" data-aos-delay="850">
                      <div className=""
                      data-aos="fade-left"
                      data-aos-offset="200"
                      data-aos-delay="500"
                      data-aos-duration="500"
                      data-aos-easing="ease-in-out"
                      >
                          <h3><span className="year">2016</span>EnerBank reaches $5 billion in cumulative loan originations</h3>
                      </div>
                  </div>
                </div>
                <div className="col-md-6"></div>
                <div className="col-md-6"></div>
                <div className="col-md-6">
                  <div className="time-block left 1998" data-aos="zoom" data-aos-delay="950">
                      <div className=""
                      data-aos="fade-right"
                      data-aos-offset="200"
                      data-aos-delay="550"
                      data-aos-duration="500"
                      data-aos-easing="ease-in-out"
                      >
                          <h3 className="text-right"><span className="year">2019</span>EnerBank serves over 10,000 contractors</h3>
                      </div>
                  </div>
                </div>
                <div className="col-md-6"></div>
                <div className="col-md-6"></div>
                <div className="col-md-6">
                  <div className="time-block 1998" data-aos="zoom" data-aos-delay="1050">
                      <div className=""
                      data-aos="fade-left"
                      data-aos-offset="200"
                      data-aos-delay="650"
                      data-aos-duration="500"
                      data-aos-easing="ease-in-out"
                      >
                          <h3><span className="year">June 2021</span>Regions announces acquisition of EnerBank USA</h3>
                      </div>
                  </div>
                </div>
                <div className="col-md-6"></div>
                <div className="col-md-6"></div>
                <div className="col-md-6">
                  <div className="time-block left 1998" data-aos="zoom" data-aos-delay="1150">
                      <div className=""
                      data-aos="fade-right"
                      data-aos-offset="200"
                      data-aos-delay="750"
                      data-aos-duration="500"
                      data-aos-easing="ease-in-out"
                      >
                          <h3 className="text-right"><span className="year">October 2021</span>Regions acquisition of<br/> EnerBank is completed</h3>
                      </div>
                  </div>
                </div>
                <div className="col-md-6"></div>
                <div className="col-md-6"></div>
                <div className="col-md-6">
                  <div className="time-block 1998" data-aos="zoom" data-aos-delay="1250">
                      <div className=""
                      data-aos="fade-left"
                      data-aos-offset="200"
                      data-aos-delay="850"
                      data-aos-duration="500"
                      data-aos-easing="ease-in-out"
                      >
                          <h3><span className="year">2022</span>Regions assets grow to $155.2 billion</h3>
                      </div>
                  </div>
                </div>
                <div className="col-md-6"></div>
                <div className="col-md-6"></div>
                <div className="col-md-6">
                  <div className="time-block left 1998" data-aos="zoom" data-aos-delay="1350">
                      <div className=""
                      data-aos="fade-right"
                      data-aos-offset="200"
                      data-aos-delay="950"
                      data-aos-duration="500"
                      data-aos-easing="ease-in-out"
                      >
                          <h3 className="text-right"><span className="year">May 2022 </span>Regions | EnerBank moves into new headquarters in downtown<br/> Salt Lake City, Utah</h3>
                      </div>
                  </div>
                </div>
                <div className="col-md-6"></div>
                <div className="col-md-6"></div>
                <div className="col-md-6">
                  <div className="time-block 1998" data-aos="zoom" data-aos-delay="1450">
                      <div className=""
                      data-aos="fade-left"
                      data-aos-offset="200"
                      data-aos-delay="1050"
                      data-aos-duration="500"
                      data-aos-easing="ease-in-out"
                      >
                          <h3><span className="year">2022</span>Regions | EnerBank reaches $16 billion in loan originations</h3>
                      </div>
                  </div>
                </div>
                <div className="col-md-6"></div>
                <div className="col-md-6"></div>
                <div className="col-md-6 hide">
                  <div className="time-block left 1998" data-aos="zoom" data-aos-delay="1550">
                      <div className=""
                      data-aos="fade-right"
                      data-aos-offset="200"
                      data-aos-delay="1150"
                      data-aos-duration="500"
                      data-aos-easing="ease-in-out"
                      >
                          <h3 className="text-right"><span className="year">September 2024 </span>Regions &#124; EnerBank name is retired, and the division officially becomes branded as Regions Home Improvement Financing</h3>
                      </div>
                  </div>
                </div>
                <div className="col-md-6"></div>
                <div className="col-md-6"></div>
                <div className="col-md-6">
                  <div className="time-block left 2024" data-aos="zoom" data-aos-delay="1450">
                      <div className=""
                      data-aos="fade-right"
                      data-aos-offset="200"
                      data-aos-delay="1050"
                      data-aos-duration="500"
                      data-aos-easing="ease-in-out"
                      >
                          <h3><span className="year">2024</span>Regions | EnerBank becomes Regions Home Improvement Financing </h3>
                      </div>
                  </div>
                </div>
              </div> 
            </div>
          </div>
        </section>
        </div>
    </Layout>
  )
}

export default RegionsPage
